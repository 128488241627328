import { useHistory, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { fetchConfig } from "../utils/fetchConfig"
import { fetchIncidentById } from "../utils/fetchIncidentById"
import { useToasts } from "react-toast-notifications"
import _ from "lodash"
import { Button, Checkbox, HTMLSelect, InputGroup, Intent, Label, Radio, RadioGroup } from "@blueprintjs/core"
import { convertToRaw, EditorState } from "draft-js"
import moment from "moment"
import { db, firebase } from "../firebase"
import { Helmet } from "react-helmet"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
//Utils
import { checkIfSignedIn } from "../utils/checkIfSignedIn"
//Styles
import styles from "../styles/Create.module.css"

function Update() {
    const { id } = useParams()
    const { addToast } = useToasts()
    const history = useHistory()
    const [config, setConfig] = useState()
    const [incident, setIncident] = useState()
    const [description, setDescription] = useState("")
    const [selectedServices, setSelectedServices] = useState([])
    const [startDate, setStartDate] = useState("")
    const [closeDate, setCloseDate] = useState("")
    const [selectedStatus, setSelectedStatus] = useState("")
    const [updateToAdd, setUpdateToAdd] = useState({})
    const [updatesList, setUpdatesList] = useState([])
    const getData = async () => {
        try {
            const config = await fetchConfig()
            setConfig(config)
            const incident = await fetchIncidentById(id)
            setIncident(incident)
        } catch (error) {
            addToast(error.message, {
                appearance: "error",
                autoDismiss: true,
            })
        }
    }
    useEffect(() => {
        checkIfSignedIn(history)
        getData()
    }, [])
    useEffect(() => {
        if (incident) {
            setDescription(incident.description)
            setSelectedServices(incident.services)
            setStartDate(moment(incident.start_date).format("YYYY-MM-DDTHH:mm"))
            if (incident.close_date) {
                setCloseDate(moment(incident.close_date).format("YYYY-MM-DDTHH:mm"))
            }
            setSelectedStatus(incident.status)
            setUpdatesList(incident.updates)
        }
    }, [incident])
    const servicesRender = () => {
        if (config) {
            return _.map(config.services, (service, index) => (
                <Checkbox
                    key={`wc-service-${index}`}
                    label={service.text}
                    checked={_.includes(selectedServices, service.key)}
                    onChange={() => {
                        let newArray
                        if (_.includes(selectedServices, service.key)) newArray = _.without(selectedServices, service.key)
                        else newArray = [...selectedServices, service.key]
                        setSelectedServices(newArray)
                    }}
                />
            ))
        }
    }
    const statusRender = () => {
        if (config) {
            return _.map(config.status, (status, index) => <Radio key={`wc-status-${index}`} label={status.text} value={status.key} />)
        }
    }
    const impactRenders = () => {
        if (config) {
            return _.map(config.impacts, (impact, index) => (
                <option key={`wc-impact-${index}`} value={impact.key}>
                    {impact.text}
                </option>
            ))
        }
    }
    //Editor
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const updatesListRender = () => {
        if (updatesList.length > 0) {
            return _.map(updatesList, (update, index) => (
                <tr key={`update-${index}`}>
                    <td>{update.impact}</td>
                    <td>{moment(update.timestamp).format("DD MMM YYYY hh:mm a")}</td>
                    <td dangerouslySetInnerHTML={{ __html: update.comments }} className={styles.updateComments} />
                    <td>
                        <Button
                            icon={"trash"}
                            intent={Intent.DANGER}
                            onClick={() => {
                                const newUpdatesList = _.filter(updatesList, (o) => o.timestamp !== update.timestamp)
                                setUpdatesList(newUpdatesList)
                            }}
                        />
                    </td>
                </tr>
            ))
        }
    }
    const updateIncident = async () => {
        if (!description) {
            addToast("Please, add a description", {
                appearance: "warning",
                autoDismiss: true,
            })
            return
        }
        if (!selectedServices.length) {
            addToast("Please, select at least one service", {
                appearance: "warning",
                autoDismiss: true,
            })
            return
        }
        if (!startDate) {
            addToast("Please, select a start date", {
                appearance: "warning",
                autoDismiss: true,
            })
            return
        }
        if (!selectedStatus) {
            addToast("Please, select status", {
                appearance: "warning",
                autoDismiss: true,
            })
            return
        }
        const incidentDocRef = db.collection("issues").doc(incident.id)
        const data = {
            description: description,
            services: selectedServices,
            start_date: moment(startDate).valueOf(),
            close_date: closeDate ? moment(closeDate).valueOf() : "",
            status: selectedStatus,
            updates: updatesList,
        }
        try {
            await incidentDocRef.update(data)
            addToast("Incident updated successfully!", {
                appearance: "success",
                autoDismiss: true,
            })
        } catch (error) {
            console.error(error)
            addToast(error.message, {
                appearance: "error",
                autoDismiss: true,
            })
        }
    }
    //Console purposes only
    useEffect(() => {
        // console.log("Updates list", updatesList)
    }, [updatesList])
    return (
        <div>
            <Helmet>
                <title>Webcat status — Admin dashboard</title>
                <meta name="title" content="Webcat status — Admin dashboard" />
                <meta name="description" content="" />
            </Helmet>
            <main className={styles.create}>
                <h1>Edit incident</h1>
                <nav>
                    <Button onClick={() => history.push("/admin/dashboard")}>Go back</Button>
                    <Button onClick={() => updateIncident()}>Update</Button>
                </nav>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div>
                        <h2>Details</h2>
                        <div>
                            <Label>
                                <p>Description</p>
                                <InputGroup type={"text"} value={description} onChange={(e) => setDescription(e.currentTarget.value)} />
                            </Label>
                        </div>
                        <div>
                            <p>Services affected</p>
                            {servicesRender()}
                        </div>
                        <div>
                            <Label>
                                <p>Start date</p>
                                <InputGroup type={"datetime-local"} value={startDate} onChange={(e) => setStartDate(e.currentTarget.value)} />
                            </Label>
                        </div>
                        <div>
                            <Label>
                                <p>Close date</p>
                                <InputGroup type={"datetime-local"} value={closeDate} onChange={(e) => setCloseDate(e.currentTarget.value)} />
                            </Label>
                        </div>
                        <div>
                            <p>Status</p>
                            <RadioGroup onChange={(e) => setSelectedStatus(e.currentTarget.value)} selectedValue={selectedStatus}>
                                {statusRender()}
                            </RadioGroup>
                        </div>
                    </div>
                    <div>
                        <h2>Updates</h2>
                        <div>
                            <Label>
                                <p>Impact</p>
                                <HTMLSelect onChange={(e) => setUpdateToAdd({ ...updateToAdd, impact: e.currentTarget.value })}>
                                    <option value={"none"} selected={true} disabled={true}>
                                        Select an option
                                    </option>
                                    {impactRenders()}
                                </HTMLSelect>
                            </Label>
                        </div>
                        <div>
                            <Label>
                                <p>Date</p>
                                <InputGroup
                                    type={"datetime-local"}
                                    onChange={(e) => setUpdateToAdd({ ...updateToAdd, timestamp: moment(e.currentTarget.value).valueOf() })}
                                />
                            </Label>
                        </div>
                        <div>
                            <Label>
                                <p>Comments</p>
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName={styles.editorWrapper}
                                    onEditorStateChange={(newState) => {
                                        setEditorState(newState)
                                        setUpdateToAdd({ ...updateToAdd, comments: draftToHtml(convertToRaw(editorState.getCurrentContent())) })
                                    }}
                                    toolbar={{
                                        options: ["inline", "list", "history", "emoji"],
                                    }}
                                />
                            </Label>
                        </div>
                        <div>
                            <Button
                                onClick={() => {
                                    if (!updateToAdd.impact) {
                                        addToast("Please, select an impact", {
                                            appearance: "warning",
                                            autoDismiss: true,
                                        })
                                        return
                                    }
                                    if (!updateToAdd.timestamp) {
                                        addToast("Please, select a date", {
                                            appearance: "warning",
                                            autoDismiss: true,
                                        })
                                        return
                                    }
                                    let newUpdatesList = [...updatesList, updateToAdd]
                                    newUpdatesList = _.orderBy(newUpdatesList, ["timestamp"], ["desc"])
                                    setUpdatesList(newUpdatesList)
                                }}
                            >
                                Add update
                            </Button>
                        </div>
                        <table>
                            <tr>
                                <th>Impact</th>
                                <th>Date</th>
                                <th>Comments</th>
                                <th>Delete</th>
                            </tr>
                            {updatesListRender()}
                        </table>
                    </div>
                </form>
            </main>
        </div>
    )
}

export default Update
