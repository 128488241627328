import { Helmet } from "react-helmet"
import { useState, useEffect } from "react"
import { useToasts } from "react-toast-notifications"
import { firebase } from "../firebase"
import { useHistory } from "react-router-dom"
import { InputGroup, Label, Button } from "@blueprintjs/core"
// Styles
import styles from "../styles/SignIn.module.css"

function SignIn() {
    const { addToast } = useToasts()
    const [user, setUser] = useState("")
    const [password, setPassword] = useState("")
    const history = useHistory()
    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) history.push("/admin/dashboard")
        })
    }, [])
    const submitCredentials = async () => {
        try {
            const response = await firebase.auth().signInWithEmailAndPassword(user, password)
            addToast(`Welcome back!`, {
                appearance: "success",
                autoDismiss: true,
            })
        } catch (error) {
            addToast(error.message, {
                appearance: "error",
                autoDismiss: true,
            })
        }
    }
    return (
        <div>
            <Helmet>
                <title>Webcat status — Admin Sign in</title>
                <meta name="title" content="Webcat status — Admin Sign in" />
                <meta name="description" content="" />
            </Helmet>
            <main className={styles.signIn}>
                <h1>Sign in</h1>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        submitCredentials()
                    }}
                >
                    <Label>
                        Email
                        <InputGroup leftIcon={"user"} type={"text"} required onChange={(e) => setUser(e.target.value)} />
                    </Label>
                    <Label>
                        Password
                        <InputGroup leftIcon={"key"} type={"password"} required onChange={(e) => setPassword(e.target.value)} />
                    </Label>
                    <Button type={"submit"}>Sign in</Button>
                </form>
            </main>
        </div>
    )
}

export default SignIn
