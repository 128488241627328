import { Route, useHistory, Switch } from "react-router-dom"
import { useEffect } from "react"
import { firebase } from "../firebase"
import { ToastProvider } from "react-toast-notifications"
//Pages
import SignIn from "./SignIn"
import Dashboard from "../Pages/Dashboard"
import Update from "./Update"
import Create from "./Create"

function Admin() {
    const history = useHistory()
    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (!user) history.push("/admin/sign-in")
        })
    }, [])
    return (
        <div>
            <ToastProvider>
                <Switch>
                    <Route path={"/admin/sign-in"}>
                        <SignIn />
                    </Route>
                    <Route path={"/admin/dashboard"}>
                        <Dashboard />
                    </Route>
                    <Route path="/admin/incident/create">
                        <Create />
                    </Route>{" "}
                    <Route path="/admin/incident/update/:id">
                        <Update />
                    </Route>
                </Switch>
            </ToastProvider>
        </div>
    )
}

export default Admin
