import { db } from "../firebase"
import _ from "lodash"

export async function fetchConfig() {
    let config = {}
    let rawConfig
    const configQuery = await db.doc("config/default").get()
    rawConfig = configQuery.data()
    // console.log("Raw config", rawConfig)
    config.services = _.orderBy(
        _.map(rawConfig.services, (obj, key) => ({ text: obj.text, key })),
        ["text"],
        ["asc"]
    )
    config.status = _.orderBy(
        _.map(rawConfig.status, (obj, key) => ({ text: obj.text, index: obj.index, iconCode: obj.iconUrl, key })),
        ["index"],
        ["asc"]
    )
    config.impacts = _.orderBy(
        _.map(rawConfig.impacts, (obj, key) => ({ text: obj.text, index: obj.index, iconCode: obj.iconUrl, key })),
        ["index"],
        ["asc"]
    )
    return config
}
