import { firebase } from "../firebase"
import { useToasts } from "react-toast-notifications"
import { useHistory } from "react-router-dom"
import { Button } from "@blueprintjs/core"

function Nav() {
    const history = useHistory()
    const { addToast } = useToasts()
    const signOut = async () => {
        try {
            await firebase.auth().signOut()
            addToast("Bye bye!", {
                appearance: "info",
                autoDismiss: true,
            })
        } catch (error) {
            addToast(error.message, {
                appearance: "error",
                autoDismiss: true,
            })
        }
    }
    return (
        <nav>
            <Button onClick={() => history.push("/admin/incident/create")}>Create new incident</Button>
            <Button onClick={signOut}>Sign out</Button>
        </nav>
    )
}

export default Nav
