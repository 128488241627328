import _ from "lodash"
import { db } from "../firebase"

export async function fetchIncidents(status = []) {
    let incidents
    const collectionRef = await db.collection("issues")
    let query = collectionRef.orderBy("start_date", "desc")
    if (status.length > 0) query = query.where("status", "array-contains-any", status)
    // if (services.length > 0) query = query.where("services", "array-contains-any", services)
    const querySnapshot = await query.get()
    // console.log("Query", querySnapshot.query)
    incidents = _.map(querySnapshot.docs, (doc) => {
        const data = doc.data()
        return { ...data, timestamp: data.timestamp.toMillis() }
    })
    return incidents
}
