import _ from "lodash"

export function getDataForHistory(incidents, config) {
    const data = {}
    _.forEach(config.services, (service) => {
        data[service.key] = { text: service.text, incidents: [] }
        _.forEach(incidents, (incident) => {
            if (_.includes(incident.services, service.key)) {
                data[service.key].incidents.push(incident)
            }
        })
    })
    // console.log("Data for history", data)
    return data
}
