import { Switch, BrowserRouter as Router, Route } from "react-router-dom"
//Pages
import Home from "./Pages/Home"
import Admin from "./Pages/Admin"
import History from "./Pages/History"
import Detail from "./Pages/Detail"

function App() {
    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route exact path={"/"}>
                        <Home />
                    </Route>
                    <Route exact path={"/incidents"}>
                        <History />
                    </Route>
                    <Route exact path={"/incidents/:id"}>
                        <Detail />
                    </Route>
                    <Route path={"/admin"}>
                        <Admin />
                    </Route>
                </Switch>
            </Router>
        </div>
    )
}

export default App
