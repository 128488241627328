import _ from "lodash"
import { Icon } from "@blueprintjs/core"
import { Link } from "react-router-dom"
//Styles
import styles from "../styles/Footer.module.css"

function Footer({ config = {}, link = {} }) {
    const legendRender = () => {
        if (config) {
            return _.map(config.impacts, (impact, index) => (
                <div className={styles.legendItem} key={`legend-item-${index}`}>
                    <Icon icon={impact.iconCode} />
                    <p>{impact.text}</p>
                </div>
            ))
        }
    }
    const linkRender = () => {
        if (link) {
            return (
                <p>
                    <Link to={link.to}>{link.text}</Link>
                    <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;All times are ET</span>
                </p>
            )
        } else {
            return (
                <p>
                    <span>All times are ET</span>
                </p>
            )
        }
    }
    return (
        <footer className={styles.footer}>
            <div className={styles.link}>{linkRender()}</div>
            <div className={styles.legend}>{legendRender()}</div>
        </footer>
    )
}

export default Footer
