import moment from "moment"

export function calcDuration(startDate, closeDate) {
    let timeReference
    if (closeDate) {
        timeReference = closeDate
    } else {
        timeReference = moment().valueOf()
    }
    const durationInMinutes = moment(timeReference).diff(startDate, "minutes")
    const durationInHours = moment(timeReference).diff(startDate, "hours")
    const minutesResidue = durationInMinutes - durationInHours * 60
    return `${durationInHours} hours, ${minutesResidue} minutes`
}
