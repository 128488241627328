import { Helmet } from "react-helmet"
import { useState, useEffect } from "react"
import moment from "moment"
import _ from "lodash"
import { Icon } from "@blueprintjs/core"
import { Link } from "react-router-dom"
// Styles
import styles from "../styles/Home.module.css"
// Utils
import { fetchConfig } from "../utils/fetchConfig"
import { fetchIncidents } from "../utils/fetchIncidents"
import { getDataForTable } from "../utils/getDataForTable"
//Components
import Footer from "../components/Footer"

function Home() {
    const [config, setConfig] = useState()
    const [incidents, setIncidents] = useState()
    const [dates, setDates] = useState()
    const [tableData, setTableData] = useState()
    const getData = async () => {
        try {
            const config = await fetchConfig()
            setConfig(config)
            const incidents = await fetchIncidents()
            setIncidents(incidents)
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        const dates = []
        for (let i = 0; i <= 7; i++) {
            dates.unshift(moment().subtract(i, "days").format("MMM DD"))
        }
        setDates(dates)
        if (!incidents || !config) return
        setTableData(getDataForTable(incidents, config, dates))
    }, [incidents])
    const tableHeaders = () => {
        return _.map(dates, (date, index) => <th key={`table-header-${index}`}>{date}</th>)
    }
    const tableContent = () => {
        if (tableData) {
            return _.map(tableData, (obj, key) => {
                const datesJSX = _.map(obj.dates, (update, key) => {
                    if (update !== "not-filled" && key !== "general") {
                        return (
                            <td key={`date-td-${key}`}>
                                <Link to={`/incidents/${update.incidentId}`}>
                                    <Icon icon={_.find(config.impacts, (imp) => imp.key === update.obj.impact).iconCode} />
                                </Link>
                            </td>
                        )
                    } else if (update !== "not-filled" && key === "general") {
                        return (
                            <td key={`date-td-${key}`}>
                                <Icon icon={_.find(config.impacts, (imp) => imp.key === update.obj.impact).iconCode} />
                            </td>
                        )
                    } else return <td key={`date-td-${key}`}> </td>
                })
                return (
                    <tr key={`td-${key}`}>
                        <td>{obj.name}</td>
                        {datesJSX}
                    </tr>
                )
            })
        }
    }
    return (
        <div>
            <Helmet>
                <title>Webcat status — Check Webcat app status</title>
                <meta name="title" content="Webcat status — Check Webcat app status" />
                <meta name="description" content="" />
            </Helmet>
            <main className={styles.home}>
                <h1>Webcat status</h1>
                <section>
                    <h2>Webcat status dashboard</h2>
                    <p>
                        Our Status page lets you know if our platform is up and running, or if there are any ongoing issues that are preventing the
                        adequate behavior of the platform.
                    </p>
                </section>
                <section style={{ display: "none" }}>
                    <h3>Note</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci alias amet aut dolores illum, ipsam modi mollitia nobis
                        omnis possimus qui quod repellendus, rerum ut voluptas. Consequuntur deleniti ducimus eius eos excepturi impedit modi nisi,
                        nobis perspiciatis quas quod, sit!
                    </p>
                </section>
                <section>
                    <table>
                        <tr>
                            <th>Service</th>
                            {tableHeaders()}
                            <th> </th>
                        </tr>
                        {tableContent()}
                    </table>
                </section>
                <Footer config={config} link={{ to: "/incidents", text: "View history" }} />
            </main>
        </div>
    )
}

export default Home
