import { Helmet } from "react-helmet"
import { useState, useEffect } from "react"
import moment from "moment"
import _ from "lodash"
import { Icon } from "@blueprintjs/core"
import { useParams } from "react-router-dom"
// Styles
import styles from "../styles/Detail.module.css"
// Utils
import { fetchConfig } from "../utils/fetchConfig"
import { fetchIncidentById } from "../utils/fetchIncidentById"
//Components
import Footer from "../components/Footer"
import Breadcrumbs from "../components/Breadcrumbs"

function Detail() {
    const { id } = useParams()
    const [config, setConfig] = useState()
    const [incident, setIncident] = useState()
    const getData = async () => {
        try {
            const config = await fetchConfig()
            setConfig(config)
            const incident = await fetchIncidentById(id)
            setIncident(incident)
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        getData()
    }, [])
    const renderIncident = () => {
        if (incident) {
            const servicesJSX = _.map(incident.services, (service) => <span>{_.find(config.services, (serv) => serv.key === service).text}</span>)
            const updatesJSX = _.map(incident.updates, (update) => (
                <tr>
                    <td>
                        <Icon icon={_.find(config.impacts, (impact) => impact.key === update.impact).iconCode} />
                    </td>
                    <td>{moment(update.timestamp).format("DD MMM YYYY hh:mm a")}</td>
                    <td dangerouslySetInnerHTML={{ __html: update.comments }} className={styles.updateComments} />
                </tr>
            ))
            return (
                <div>
                    <h2>Incident affecting {servicesJSX}</h2>
                    <p>{incident.description}</p>
                    <p>
                        Incident began at <span style={{ fontWeight: "bold" }}>{moment(incident.start_date).format("DD MMM YYYY hh:mm a")}</span>{" "}
                        {incident.close_date ? "and ended at " : ""}
                        <span style={{ fontWeight: "bold" }}>
                            {incident.close_date ? moment(incident.close_date).format("DD MMM YYYY hh:mm a") : ""}
                        </span>{" "}
                        (all times are <span style={{ fontWeight: "bold" }}>ET</span>).
                        {}
                    </p>
                    <table>
                        <tr>
                            <th> </th>
                            <th>Date</th>
                            <th>Description</th>
                        </tr>
                        {updatesJSX}
                    </table>
                </div>
            )
        }
    }
    const renderBreadcrumbs = () => {
        if (incident) {
            const links = [
                { title: "Webcat status dashboard", to: "/" },
                { title: "Incidents", to: "/incidents" },
                { title: incident.description, to: `/incidents/${incident.id}` },
            ]
            return <Breadcrumbs links={links} />
        }
    }
    return (
        <div>
            <Helmet>
                <title>Webcat status — Check Webcat app status</title>
                <meta name="title" content="Webcat status — Check Webcat app status" />
                <meta name="description" content="" />
            </Helmet>
            <main className={styles.history}>
                <h1>Webcat status</h1>
                {renderBreadcrumbs()}
                <section>
                    <h2>Webcat status dashboard</h2>
                    <p>
                        Our Status page lets you know if our platform is up and running, or if there are any ongoing issues that are preventing the
                        adequate behavior of the platform.
                    </p>
                </section>
                <section style={{ display: "none" }}>
                    <h3>Note</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci alias amet aut dolores illum, ipsam modi mollitia nobis
                        omnis possimus qui quod repellendus, rerum ut voluptas. Consequuntur deleniti ducimus eius eos excepturi impedit modi nisi,
                        nobis perspiciatis quas quod, sit!
                    </p>
                </section>
                <section className={styles.incidentSection}>{renderIncident()}</section>
                <Footer config={config} link={{ to: "/", text: "View timeline" }} />
            </main>
        </div>
    )
}

export default Detail
