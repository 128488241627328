import { Icon } from "@blueprintjs/core"
import { Link } from "react-router-dom"
import _ from "lodash"
//Styles
import styles from "../styles/Breadcrumbs.module.css"

function Breadcrumbs({ links }) {
    const breadcrumbsRender = () => {
        if (links.length > 0) {
            return _.map(links, (link, index) => {
                return (
                    <div key={`breadcrumb-item-${index}`}>
                        <Link to={link.to}>
                            <p>{link.title}</p>
                        </Link>
                        {index !== links.length - 1 ? (
                            <Icon
                                icon={"chevron-right"}
                                color={"#6c757d"}
                                style={{
                                    margin: "0 0.5rem",
                                }}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                )
            })
        }
    }
    return <div className={styles.breadcrumbs}>{breadcrumbsRender()}</div>
}

export default Breadcrumbs
