import { db } from "../firebase"

export async function fetchIncidentById(id) {
    let incident
    const query = db.collection("issues").where("id", "==", id)
    const querySnapshot = await query.get()
    querySnapshot.docs.forEach((doc) => {
        const data = doc.data()
        incident = { ...data, timestamp: data.timestamp.toMillis() }
    })
    // console.log("Incident by id", incident)
    return incident
}
