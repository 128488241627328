import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCijGqAXpVlML3Swj9e8opoStCO4ySHzew",
  authDomain: "webcat-status.firebaseapp.com",
  projectId: "webcat-status",
  storageBucket: "webcat-status.appspot.com",
  messagingSenderId: "1024536991974",
  appId: "1:1024536991974:web:637a42fc9bb29bf1334327",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();

export { db, firebase };
