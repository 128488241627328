import _ from "lodash"
import moment from "moment"

export function getDataForTable(incidents, config, dates) {
    const data = {}
    _.forEach(config.services, (service) => {
        data[service.key] = { name: service.text, updates: [], dates: {} }
        _.forEach(dates, (date) => (data[service.key].dates[date] = "not-filled"))
        data[service.key].dates["general"] = "not-filled"
        _.forEach(incidents, (incident) => {
            if (_.includes(incident.services, service.key)) {
                _.forEach(incident.updates, (update) =>
                    data[service.key].updates.push({
                        incidentId: incident.id,
                        obj: update,
                        impactLevel: _.find(config.impacts, (impact) => impact.key === update.impact).index,
                    })
                )
            }
        })
        _.forEach(data[service.key].updates, (update) => {
            if (data[service.key].dates[moment(update.obj.timestamp).format("MMM DD")] === "not-filled") {
                data[service.key].dates[moment(update.obj.timestamp).format("MMM DD")] = update
            } else {
                if (data[service.key].dates[moment(update.obj.timestamp).format("MMM DD")].impactLevel > update.impactLevel) {
                } else {
                    data[service.key].dates[moment(update.obj.timestamp).format("MMM DD")] = update
                }
            }
            if (data[service.key].dates["general"] === "not-filled") {
                data[service.key].dates["general"] = update
            } else {
                if (data[service.key].dates["general"].obj.timestamp < update.obj.timestamp) {
                    data[service.key].dates["general"] = update
                }
            }
        })
        if (data[service.key].dates["general"] === "not-filled") {
            data[service.key].dates["general"] = {
                incidentId: "",
                obj: {
                    impact: "available",
                },
            }
        }
    })
    // console.log("Data for table", data)
    return data
}
