import { Helmet } from "react-helmet"
import { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import { Link, useHistory } from "react-router-dom"
import _ from "lodash"
import moment from "moment"
import { Button, Checkbox } from "@blueprintjs/core"
//Utils
import { fetchConfig } from "../utils/fetchConfig"
import { fetchIncidents } from "../utils/fetchIncidents"
import { calcDuration } from "../utils/calcDuration"
import { checkIfSignedIn } from "../utils/checkIfSignedIn"
//Components
import Nav from "../components/Nav"
import styles from "../styles/Dashboard.module.css"

function Dashboard() {
    const history = useHistory()
    const { addToast } = useToasts()
    const [config, setConfig] = useState(null)
    const [incidents, setIncidents] = useState(null)
    const [servicesToFilter, setServicesToFilter] = useState([])
    const [statusToFilter, setStatusToFilter] = useState([])
    const getConfig = async () => {
        try {
            const config = await fetchConfig()
            // console.log("Config", config)
            setConfig(config)
        } catch (error) {
            addToast(error.message, {
                appearance: "error",
                autoDismiss: true,
            })
        }
    }
    const getIncidents = async () => {
        try {
            const incidents = await fetchIncidents(statusToFilter)
            // console.log("Incidents", incidents)
            setIncidents(incidents)
        } catch (error) {
            console.error(error)
            addToast(error.message, {
                appearance: "error",
                autoDismiss: true,
            })
        }
    }
    useEffect(() => {
        checkIfSignedIn(history)
        getConfig()
        getIncidents()
    }, [])
    const servicesFilter = () => {
        if (config) {
            return _.map(config.services, (service, index) => (
                <Checkbox
                    key={`wc-service-filter-${index}`}
                    label={service.text}
                    checked={_.includes(servicesToFilter, service.key)}
                    onChange={(e) => {
                        let newArray
                        if (_.includes(servicesToFilter, e.target.value)) newArray = _.without(servicesToFilter, e.target.value)
                        else newArray = [...servicesToFilter, e.target.value]
                        setServicesToFilter(newArray)
                    }}
                />
            ))
        }
    }
    const statusFilter = () => {
        if (config) {
            return _.map(config.status, (status, index) => (
                <Checkbox
                    key={`wc-status-filter-${index}`}
                    label={status.text}
                    checked={_.includes(servicesToFilter, status.key)}
                    onChange={(e) => {
                        let newArray
                        if (_.includes(statusToFilter, e.target.value)) newArray = _.without(statusToFilter, e.target.value)
                        else newArray = [...statusToFilter, e.target.value]
                        setStatusToFilter(newArray)
                    }}
                />
            ))
        }
    }
    const incidentsList = () => {
        if (incidents) {
            return _.map(incidents, (incident, index) => {
                const servicesAffected = _.map(incident.services, (servKey) => _.find(config.services, (service) => service.key === servKey).text)
                return (
                    <tr key={`incident-${index}`}>
                        <td>
                            <Link to={`/admin/incident/update/${incident.id}`}>
                                <h3>{incident.description}</h3>
                            </Link>
                        </td>
                        <td>{incident.status}</td>
                        <td>{moment(incident.start_date).format("MMM DD")}</td>
                        <td>{_.join(servicesAffected, ", ")}</td>
                        <td>{calcDuration(incident.start_date, incident.close_date)}</td>
                    </tr>
                )
            })
        }
    }
    return (
        <div>
            <Helmet>
                <title>Webcat status — Admin dashboard</title>
                <meta name="title" content="Webcat status — Admin dashboard" />
                <meta name="description" content="" />
            </Helmet>
            <main className={styles.dashboard}>
                <h1>Admin dashboard</h1>
                <Nav />
                <div className={styles.dashboardFilter}>
                    <h2>Filter by</h2>
                    <div>
                        <p>Service</p>
                        {servicesFilter()}
                    </div>
                    <div>
                        <p>Status</p>
                        {statusFilter()}
                    </div>
                    <Button type={"button"} onClick={() => getIncidents()}>
                        Apply filters
                    </Button>
                </div>
                <div className={styles.dashboardTable}>
                    <h2>Incidents</h2>
                    <table>
                        <tr>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Services affected</th>
                            <th>Duration</th>
                        </tr>
                        {incidentsList()}
                    </table>
                </div>
            </main>
        </div>
    )
}

export default Dashboard
